
export function defaultPortfolio(count = 1) {
    let items = [];

    for (let i = 0; i < count; i++) {
        items.push({
            'ticker': '',
            'shares': '',
            'weight': 0
        });
    }

    return items;
}

// function applyPrice(item, prices) {
//     const price = prices[item.ticker];
//     return {
//         'ticker': item.ticker,
//         'shares': item.shares,
//         'weight': item.shares * price
//     }
// }

export function calcWeights(tickers, shares, prices) {

    const results = [];

    let total = 0;
    for (let i = 0; i < tickers.length; i++) {
        let s = 0.0;
        if (tickers[i] in prices) {
            s = prices[tickers[i]] * shares[i];
        }
        results.push(s)
        total += s;
    }

    if (results.length > 0) {
        for (let i = 0; i < tickers.length; i++) {
            results[i] = results[i] / total;
        } 
    }

    return [results, total];
}

export function fetchPrices(tickers) {
    const searchKey = tickers
        .map(x => (x.length < 6 && x.length > 0) ? x : null)
        .filter(String)
        .join(',');

    if (searchKey.length === 0) {
        const prices = {};
        tickers.forEach(ticker => {
            prices[ticker] = 0.0;
        });

        return new Promise(() => { return prices});
    }

    return fetch(`/api/last/${searchKey}`, {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'accepts': 'application/json'
        }})
        .then(response => {
            if (response.ok){
                return response.json()
            }
            throw response;
        })
        .then(s => {
            const prices = {};
            s.forEach(el => {
                prices[el.ticker] = el.price;
            });
            return prices;
        })
        .catch(error => {
            console.error(error);
            return {};
        })
        .then(prices =>  {
            tickers.forEach(ticker => {
                if (!(ticker in prices)) {
                    prices[ticker] = 0.0;
                }
            });
            return prices;
        });
}
