import './AboutPage.css'
import { Helmet } from "react-helmet";

function generatenameobf() {
    let a = 'w'
    for (let i = 0; i < 2; i++) {
        a = a + 'w';
    }
    const name = a + '.wheresh';
    return name + 'arpe.com';
}

function generatesignobf() {
    const apart = 'Man'
    const s1 = ['t', 'a', 's', ' '];
    const s2 = ['B', 'r', 'a', 'z', 'd'];
    let conc = apart;
    for (let i = 0; i < s1.length; i++) {
      conc += s1[i];
    }
    for (let i = 0; i < s2.length; i++) {
      conc += s2[i];
    }

    const s3 = ' - 2023'
  
    return conc + 'zionis' + s3;
  }

export default function AboutPage() {
    return (
        <div className="abt-content abt-text">

            <Helmet>
                <title>About</title>
                <meta name="description" content="Information about this optimization website." />
                <meta property="og:url" content="https://www.wheresharpe.com/about"/>
                <meta property="og:title" content="About Where is Sharpe?"/>
                <meta property="og:description" content="Information about this optimization website."/>
            </Helmet>

            <h2>{generatenameobf()}</h2>
            <p>Where Sharpe is a website to find where optimal sharpe ratio weights are. </p>
            <p>Sharpe ratio is defined as mean daily returns over its standard deviation or in simple terms highest stable returns. 
            This application provides tools to 'explore' and find optimal portfolios with different combinations of tickers.
            </p>
            <p>
            Note that sharpe definition includes the risk free rate that is subtracted from expected returns, but to keep it consistent this variable is omitted in this application context.</p>
            <h3>What is this?</h3>
            <p>
            This is a portfolio optimization tool written as a web application aimed to find optimal tickers and weights using S&P500 constituents.
            Here are provided 3 main tools in this website: 
            </p>
            <p>
            <b>Summary page</b> - designed to observe market changes together with moving averages and probabilities of occurrence relative to the moving average.
            </p>
            <p>
            <b>Optimization tool</b> - to find optimal weights for designed criteria (Minimum variance or Maximum sharpe ratio portfolio as an example).
            </p>
            <p>
            <b>Screener</b> - to find companies that are worth including in your portfolio (to each personal preferences).
            </p>

            <h3>Why?</h3>
            <p>Because I needed something like this for my personal use. It started as a personal project to replace python scripts that were growing out of control.</p>

            <h3>Is it free to use?</h3>
            <p>Yes, it's free for personal use as it is provided without warranty of any kind. Use at your own risk.</p>

            <h3>How often is the data updated?</h3>
            <p>It is aimed to be around 30 minutes after end of day trading period.</p>

            <br></br>
            <p className='abt-dark'><i>{generatesignobf() + ' - early release version'}</i></p>
        </div>
    );
}