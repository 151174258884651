import NotFoundPage from './pages/NotFoundPage';
import NavBar from './components/NavBar';
import SideBar from './components/SideBar';
import ExplorePage from './pages/ExplorePage';
import AboutPage from './pages/AboutPage';
import ContentErrorBoundary from './components/ContentErrorBoundary';
import Placeholder from './components/Placeholder';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Suspense, useState, useEffect, lazy } from 'react';
import { fetchPrices, calcWeights } from './utils/Portfolio';
import { loadPortfolio, savePortfolio } from './utils/State';
import { fetchConstituents, fetchHistoric } from './utils/Data';
import { dropna, pctChange } from './utils/Statistics';
import { arrayEquals } from './utils/Functions';

function validateTickersAndWeights(tickers, weights, constituents) {
  const rt = [];
  const rw = [];
  for (let i = 0; i < tickers.length; i++) {
    if (tickers[i] in constituents) {
      rt.push(tickers[i]);
      rw.push(weights[i]);
    }
  }
  return [rt, rw];
}

function validateTickers(tickers, constituents) {
  const rt = [];
  for (let i = 0; i < tickers.length; i++) {
    if (tickers[i] in constituents) {
      rt.push(tickers[i]);
    }
  }
  return rt;
}

const SummaryPage = lazy(() => import('./pages/SummaryPage'));
const ScreenPage = lazy(() => import('./pages/ScreenPage'));
const OptimizePage = lazy(() => import('./pages/OptimizePage'));


function App() {

  // Strip out anything related to plotly logging
  console.trace = null;

  const [constituents, setConstituents] = useState({});
  const [total, setTotal] = useState(0);
  const [tickers, setTickers] = useState(loadPortfolio()[0]);
  const [shares, setShares] = useState(loadPortfolio()[1]);
  const [prices, setPrices] = useState({});

  const [validTickers, setValidTickers] = useState([]);

  const [spy, setSpy] = useState({
    'values': [],
    'it': 0
  });
  const [weights, setWeights] = useState([0]);
  const [applied, setApplied] = useState({
    'tickers': [],
    'weights': [],
    'dates': [],
    'values': [],
    'it': 0
  });

  useEffect(() => {
    fetchHistoric(['SPY'])
    .then(result => {
        setSpy(old => ({
          values: dropna(pctChange(result['SPY'])),
          it: old['it'] + 1
        }));
    })
  },[]);

  const incApply = () => {
    const [vt, wt] = validateTickersAndWeights(tickers, weights, constituents);

    if (arrayEquals(vt, applied['tickers'])) {
      setApplied((old) => ({
        tickers: vt,
        weights: wt,
        dates: old['dates'],
        values: old['values'],
        it: old['it'] + 1
      }));

      return;
    }

    fetchHistoric(vt)
    .then(result => {
        const tick = Object.keys(result).filter(x => x !== 'date');
        const tickReordered = [];
        tickers.forEach(t => {
            if (tick.includes(t)){
                tickReordered.push(t);
            }
        });

        const values = [];
        tickReordered.forEach(key => {
            values.push({
                'key': key,
                'values': result[key]
            });
        })

        setApplied((old) => ({
          tickers: vt,
          weights: wt,
          dates: result['date'],
          values: values,
          it: old['it'] + 1
        }));
    })
  };

  useEffect(() => {
    fetchConstituents()
        .then(cons => {
            setConstituents(cons)
        })
  },[]);

  useEffect(() => {
    const vt = validateTickers(tickers, constituents);

    if (!arrayEquals(vt, validTickers)) {
      setValidTickers(vt);
    };
    // Could be done better
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickers, constituents]);

  useEffect(() => {
    fetchPrices(validTickers)
      .then(prices => setPrices(prices))
  }, [validTickers]);

  useEffect(() => {
    const [w, t] = calcWeights(tickers, shares, prices)
    setWeights(w);
    setTotal(t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prices, shares]);

  useEffect(() => {
    if (applied['it'] < 1 && weights.length > 1) {
      if (Number.isFinite(weights[0])) {
        incApply();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applied, weights]);

  const onNew = () => {
    setTickers([...tickers, '']);
    setShares([...shares, '']);
  }

  const onClear = () => {
    setTickers(x => x.slice(0,-1));
    setShares(x => x.slice(0,-1));
  }

  const onSave = () => {
    const [loadedTickers, loadedShares] = savePortfolio(tickers, shares)
    setTickers(loadedTickers);
    setShares(loadedShares);
    onApply();
  }

  const onTickerChanged = (i, e) => {
    const updatedTickers = [...tickers];
    updatedTickers[i] = e.target.value.toUpperCase();
    setTickers(updatedTickers);
  }

  const onShareChanged = (i, e) => {
    const updatedShares = [...shares];
    updatedShares[i] = e.target.value;
    setShares(updatedShares);
  }

  const onApply = () => {
    incApply();
  }

  return (
    <div className="app">
      <Router>
        <div>

          <div className="nav-bar">
            <NavBar/>
          </div>

          <div className="nav-side">
            <SideBar 
              tickers={tickers} 
              shares={shares} 
              weights={weights}
              total={total}
              constituents={constituents}
              onTickerChanged={onTickerChanged}
              onShareChanged={onShareChanged}
              onAddNew={onNew}
              onApply={onApply}
              onClear={onClear}
              onSave={onSave}
              />
          </div>

          <div className="nav-content">
            <ContentErrorBoundary>
              <Routes>
                <Route exact path="/" element={
                  <Suspense fallback={<Placeholder/>}>
                    <SummaryPage applied={applied}/>
                  </Suspense>  
                }/>
                <Route exact path="/screen" element={
                  <Suspense fallback={<Placeholder/>}>
                    <ScreenPage tickers={tickers}/>
                  </Suspense>  
                }/>
                <Route exact path="/optimize" element={
                  <Suspense fallback={<Placeholder/>}>
                    <OptimizePage applied={applied} spy={spy}/>
                  </Suspense>  
                }/>
                <Route exact path="/explore" element={<ExplorePage/>}/>
                <Route exact path="/about" element={<AboutPage/>}/>
                <Route path="*" element={<NotFoundPage/>}/>
              </Routes>
            </ContentErrorBoundary>
          </div>

          <div className="nav-footer">
            <div className="nav-footer-text">
              <p>Use at your own risk. 
              </p>
              <p>
                There is no warranty or guarantee of model accuracy or data validity.</p>
              <p>
                Data updated daily after market close.
                This website utilizes local storage and web workers, 
                make sure these features are supported by your current browser.
              </p>
              <p>
                Cookie policy: No cookies are stored/used/collected directly. Only performance metrics can/will be collected where necessary.
              </p>
              <p>
                www.wheresharpe.com - V0.3 early release version
              </p>
            </div>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
