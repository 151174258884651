

export function defaultSPY() {
    return {
        'date': [],
        'open': [],
        'high': [],
        'low': [],
        'close': [],
        'volume': [],
        'volatility': []
    }
}

export function defaultOptions() {
    return {
        date: [],
        total: {
            lower: [],
            mode: [],
            upper: [],
        },
        calls: {
            lower: [],
            mode: [],
            upper: [],
        },
        puts: {
            lower: [],
            mode: [],
            upper: [],
        }
    };
}

export function defaultHistoric(tickers) {
    const results = {};
    tickers.forEach(t => {
        results[t] = [];
    });
    return results;
}

function toohlc(data) {
    const date = Object.keys(data['close']);
    const open = Object.values(data['open']);
    const high = Object.values(data['high']);
    const low = Object.values(data['low']);
    const close = Object.values(data['close']);
    const volume = Object.values(data['volume']);

    const newDate = [];
    date.forEach(d => {
        newDate.push(d.replaceAll("/", "-"));
    });

    return {
        'date': newDate,
        'open': open,
        'high': high,
        'low': low,
        'close': close,
        'volume': volume
    }
}

export function getPlotMinMax(data, minpad, maxpad) {
    if (data.length === 0) {
        return [0, 1];
    }

    let min = 1e9
    let max = -1e9

    for (let i = 0; i < data.length; i++) {
        const element = data[i];
        min = Math.min(min, element);
        max = Math.max(max, element);
    }

    const range = max - min;

    return [min - minpad * range, max + maxpad * range]
}

export function slice(data, n) {
    const newData = {};
    Object.keys(data).forEach(el => {
        newData[el] = data[el].slice(-n)
    });

    return newData;
}

export function fetchSPY() {
    return fetch('/api/spy/', {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'accepts': 'application/json'
    }})
    .then(response => {
        if (response.ok){
            return response.json()
        }
        throw response;
    })
    .then(data => {
        return toohlc(data);
    })
    .catch(error => {
        console.error(error);
        return defaultSPY();
    })
}

export function fetchOptions() {
    return fetch('/api/options/', {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'accepts': 'application/json'
    }})
    .then(response => {
        if (response.ok){
            return response.json()
        }
        throw response;
    })
    .then(data => {
        return data;
    })
    .catch(error => {
        console.error(error);
        return {};
    })
}

function map2stats(ticker, header, values) {

    const val = (x) => {
        const idx = header.indexOf(x);
        if (idx < 0) {
            return NaN;
        }
        else {
            return values[idx];
        }
    }

    return {
        'ticker': ticker,
        'mean_1y': val('mean_1y') * 1,
        'std_1y': val('std_1y') * 1,
        'corr_1y': val('corr_1y') * 1,
        'beta_1y': val('beta_1y') * 1,
        'sharpe_1y': (val('mean_1y') * 252) / (val('std_1y') * Math.sqrt(252)),
        'sharpe_5y': (val('mean_5y') * 252) / (val('std_5y') * Math.sqrt(252)),
        'ev_pos_1y': val('ev_pos_1y') * 100,
        'ev_neg_1y': val('ev_neg_1y') * 100,
        'ev_diff_1y': (val('ev_pos_1y') + val('ev_neg_1y')) * 100,
        'var_1y': val('var_1y') * 100,
        'cvar_1y': val('cvar_1y') * 100,
        'mean_s_1y': val('mean_s_1y') * 1,
        'mean_1y_ann': val('mean_1y') * 252 * 100,
        'std_1y_ann': val('std_1y') * Math.sqrt(252) * 100,
        'std_s_1y': val('std_s_1y') * 1,
        'skew_s_1y': val('skew_s_1y') * 1,
        // 'sharpe_s_1y': val('mean_s_1y') / val('std_s_1y'),
        'ev_neg_ev_1y': val('ev_tot_1y') / Math.abs(val('ev_neg_1y')), // Expected value over negative expected value
        'change_1y': val('change_1y') * 1,
        'dividend': val('dividend_yield') * 100,
        'capm_diff_1y': val('capm_diff_1y') * 1,
        'pe': val('pe_ttm') * 1,
        'earnings_growth': val('earnings_growth') * 1,
        'current': val('current_ratio') * 1,
        'quick': val('quick_ratio') * 1,
        'mkt_cap': val('market_cap') / 1e9,
        'industry': val('industry')
    }
}

export function fetchStats() {
    return fetch('/api/stats-all/', {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'accepts': 'application/json'
    }})
    .then(response => {
        if (response.ok){
            return response.json()
        }
        throw response;
    })
    .then(s => {

        const results = [];
        for (let i = 0; i < s['tickers'].length; i++) {
            results.push(map2stats(s['tickers'][i], s['header'], s['values'][i]));
        }
        return results;
    })
    .catch(error => {
        console.error(error);
        return [];
    })
}

export function fetchHistoric(tickers) {
    const searchKey = tickers
        .map(x => (x.length < 6 && x.length > 0) ? x : null)
        .filter(String)
        .join(',');

    if (searchKey.length === 0) {
        const historic = {};
        tickers.forEach(ticker => {
            historic[ticker] = 0.0;
        });

        return new Promise(() => { return historic});
    }

    return fetch(`/api/historic/${searchKey}?range=5y`, {
        method: 'GET',
        cache: 'default',
        headers: { 
            'Content-Type': 'application/json',
            'accepts': 'application/json'
    }})
    .then(response => {
        if (response.ok){
            return response.json()
        }
        throw response;
    })
    .then(data => {
        return data
    })
    .catch(error => {
        console.error(error);
        return defaultHistoric(tickers);
    })
}

export function fetchConstituents() {

    return fetch('/api/constituents/', {
        method: 'GET',
        cache: 'default',
        headers: { 
            'Content-Type': 'application/json',
            'accepts': 'application/json'
    }})
    .then(response => {
        if (response.ok){
            return response.json()
        }
        throw response;
    })
    .then(data => {
        const result = {};
        for (let i = 0; i < data.length; i++) {
            result[data[i]] = null;
        }
        return result;
    })
    .catch(error => {
        console.error(error);
        return {};
    })
}