
function defaultTickers() {
    return ['AAPL', 'GOOGL', 'XOM', 'JPM', '']
}

function defaultShares() {
    return [1, 1, 1, 1, '']
}

function safeWrapPortfolioReturn(tickers, shares){

    if (tickers === null) {
        tickers = defaultTickers();
    }
    if(shares === null) {
        shares = defaultShares();
    }

    if (!Array.isArray(tickers)){
        tickers = [tickers];
    }
    if (!Array.isArray(shares)){
        shares = [shares];
    }

    // if (tickers.length === 0) {
    //     tickers = defaultTickers();
    // }
    // else if (shares.length === 0) {
    //     shares = defaultShares();
    // }

    return [tickers, shares];
}

function isNumeric(str) {
    return !isNaN(str);
}


function trimPortfolio(tickers, shares) {

    let newTickers = tickers;
    let newShares = shares;

    // Ensure same size
    if (tickers.length < shares.length){
        newShares = shares.slice(0, tickers.length);
    }
    else if (tickers.length > shares.length){
        newTickers = tickers.slice(0, shares.length);
    }

    // Filter
    const isTickerNotValid = newTickers.map(x => x.length === 0);
    const isShareNotValid = newShares.map(x => !isNumeric(x));

    const isNotValid = isTickerNotValid.map((x, i) => x || isShareNotValid[i]);

    newTickers = newTickers.filter((x,i) => !isNotValid[i]);
    newShares = newShares.filter((x,i) => !isNotValid[i]);

    return safeWrapPortfolioReturn(newTickers, newShares);
}

export function savePortfolio(tickers, shares) {
    const [tickersFiltered, sharesFiltered] = trimPortfolio(tickers, shares);

    localStorage.setItem('tickers', JSON.stringify(tickersFiltered));
    localStorage.setItem('shares', JSON.stringify(sharesFiltered));

    return [tickersFiltered, sharesFiltered]
}

export function loadPortfolio() {

    let tickers = defaultTickers();
    let shares = defaultShares();
    try {
        tickers = JSON.parse(localStorage.getItem('tickers'));
        shares = JSON.parse(localStorage.getItem('shares'));
    }
    catch {
        console.warn('Failed to deserialze portfolio');
    }

    return safeWrapPortfolioReturn(tickers, shares);
}

export function setStored(key, value) {

    try {
        localStorage.setItem(key, value);
    } catch (error) {
        console.error(error);
    }
}

export function getStored(key, defaultValue) {

    try {
        let value = localStorage.getItem(key);
        if (value === null){
            value = defaultValue;
        }
    
        return value;
    } catch (error) {
        console.error(error);
    }

    return defaultValue;
}

export function getStoredJSON(key, defaultValue) {

    try {
        let value = localStorage.getItem(key);
        if (value === null){
            value = defaultValue;
        }
        else{
            value = JSON.parse(value);
        }
    
        return value;
    } catch (error) {
        console.error(error);
    }

    return defaultValue;
}

export function setStoredJSON(key, value) {

    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error(error);
    }
}

// export function useStorage(key, defaultValue) {

//     try {
        
//     } catch (error) {
        
//     }

//     let value = localStorage.getItem(key);
//     if (value === null){
//         value = defaultValue;
//     }
//     const setter = (v) => {
//         localStorage.setItem(key, v);
//     }

//     return [value, setter];
// }