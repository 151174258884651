

export default function Placeholder() {

    const style = {
        margin: '60px 4vw 100px 4vw'
    };

    const fontColor = '#818181';
    const bgColor = '#323232';

    const fillerStyle = { 
        padding: '10px', 
        marginTop: '10px', 
        marginBottom: '50px', 
        color: '#868686',
        minHeight: '25px',
        borderRadius: '10px', 
        backgroundColor: bgColor
    };

    return(
        <div style={style}>

            <h3 style={{color: fontColor}}>Loading...</h3>
            <p style={fillerStyle}></p>
            <p style={fillerStyle}></p>
            <p style={fillerStyle}></p>
            <p style={fillerStyle}></p>
            <p style={fillerStyle}></p>
        </div>
        
    )
}