
export function mul(array, value) {
    const result = [];
    for (let i = 0; i < array.length; i++) {
        result.push(array[i] * value);
    }

    return result;
}

function calcMean(data) {

    let sum = 0;
    for (let i = 0; i < data.length; i++) {
        sum += data[i];
    }
    return sum / data.length;
}

function calcCov(data1, data2, mean1, mean2) {
    let sum = 0;
    for (let i = 0; i < data1.length; i++) {
        sum += (data1[i] - mean1) * (data2[i] - mean2);
    }

    return sum / (data1.length - 1)
}

function calcCorr(data1, data2, mean1, mean2) {
    let sum1 = 0;
    let sum2 = 0;
    let sum3 = 0;
    for (let i = 0; i < data1.length; i++) {
        sum1 += (data1[i] - mean1) * (data2[i] - mean2);
        sum2 += (data1[i] - mean1) * (data1[i] - mean1);
        sum3 += (data2[i] - mean2) * (data2[i] - mean2);
    }

    return sum1 / (Math.sqrt(sum2 * sum3));
}

export function arrayEquals(a, b) {
    if (a.length !== b.length) {
        return false;
    }

    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false;
        }
    }

    return true;
}

export function range2slice(range) {
    switch (range) {
        case '6m':
            return 126;
        
        case '1y':
            return 252;

        case '2y':
            return 252 * 2;

        case '5y':
            return 252 * 5;
    
        default:
            return 252;
    }
}

export function calcMeanCovCorr(data, range) {

    data = [...data];

    const keys = [];
    const mean = [];
    const r = range2slice(range);
    for (let i = 0; i < data.length; i++) {
        keys.push(data[i].key);
        data[i].values = data[i].values.slice(-r);
    }

    for (let i = 0; i < data.length; i++) {
        mean.push(calcMean(data[i].values));
    }

    const cov = [];
    const corr = [];
    for (let i = 0; i < data.length; i++) {
        const inner1 = [];
        const inner2 = [];
        for (let u = 0; u < data.length; u++) {
            inner1.push(calcCov(
                data[i].values,
                data[u].values,
                mean[i],
                mean[u]
            ));
            inner2.push(calcCorr(
                data[i].values,
                data[u].values,
                mean[i],
                mean[u]
            ));
        }
        cov.push(inner1);
        corr.push(inner2);
    }

    return [keys, mean, cov, corr];
}

export function linspace(start, stop, num, endpoint = true) {
    const div = endpoint ? (num - 1) : num;
    const step = (stop - start) / div;
    return Array.from({length: num}, (_, i) => start + step * i);
}
export function minmax(values) {

    let min = 1e12;
    let max = -1e12;

    for (let i = 0; i < values.length; i++) {
        min = Math.min(min, values[i]);
        max = Math.max(max, values[i]);
    }

    return [min, max];
}

export function sub(x1, x2) {
    if (x1.length !== x2.length) {
        throw new Error('Not equal dimensions');
    }

    const result = [];
    for (let i = 0; i < x1.length; i++) {
        result[i] = x1[i] - x2[i];
    }

    return result;
}

export function spaceColor(value, cmin, cmax) {

    const c = 155;
    const o = 100;
    let nv = ((value - cmin) / (cmax - cmin) * c);
    let g = (nv + o)
    let r = (c - nv + o)
    g = Math.min(255, Math.max(0, g));
    r = Math.min(255, Math.max(0, r));
    return `rgb(${r.toFixed(0)}, ${g.toFixed(0)}, 110)`;
}

export function spaceZeroColor(value, cmax) {

    const c = 155;
    const o = 100;
    let nv = ((Math.abs(value)-cmax) / (-cmax) * c);
    let g = (nv + o)
    let r = (c - nv + o)
    g = Math.min(255, Math.max(0, g));
    r = Math.min(255, Math.max(0, r));
    return `rgb(${r.toFixed(0)}, ${g.toFixed(0)}, 110)`;
}

export function spaceColorLim(value, cmin, cmax, lim) {

    if (value === lim) {
        return 'rgb(255, 50, 110)';
    }
    return spaceColor(value, cmin, cmax);
}