import './PortfolioCollection.css'

export function PortfolioItem({ticker, shares, weight, valid, onTickerChanged, onSharesChanged}) {

    if (typeof(weight) === 'number') {
        weight = weight.toFixed(2);
    }
    else {
        weight = '0.00'
    }

    let textClass = 'item-ticker';
    if (!valid) {
        textClass += ' not-valid-ticker';
    }

    return (
        <div className="item-container">
            <input type='text' spellcheck='false' placeholder='AAPL' className={textClass} value={ticker} onChange={onTickerChanged}/>
            <input type='text' className='item-hash' defaultValue='@' disabled={true}/>
            <input type='text' spellcheck='false' placeholder='1.0' className="item-count" value={shares} onChange={onSharesChanged}/>
            <input type='text' className='item-weight' value={weight} disabled={true}/>
        </div>
    );
}


export function PortfolioCollection({
        tickers, 
        shares, 
        weights, 
        constituents,
        onTickerChanged, 
        onShareChanged
    }) {
    const results = [];

    for (let i = 0; i < tickers.length; i++) {
        results.push(
            <PortfolioItem 
                key={i.toString()} 
                ticker={tickers[i]}
                shares={shares[i]}
                weight={weights[i]}
                valid={tickers[i] in constituents}
                onTickerChanged={(e) => onTickerChanged(i, e)}
                onSharesChanged={(e) => onShareChanged(i, e)}
            />
        );
    }

    return (
        <div>
            {results}
        </div>
    );
}