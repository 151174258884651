import React from 'react';

export default class ContentErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, message: ''};
    }

    static getDerivedStateFromError(error) {
        console.log('error',error)
        return { hasError: true, message: error.message};
    }

    render() {
        if (!this.state.hasError) {
            return this.props.children;
        }

        return (
            <div>
                <h1>Whooops</h1>
                <h3>Something went wrong &#129300;</h3>
                <h3>Sorry for this, please try again later...</h3>
                <a href="/">Refresh</a>
                <br></br>
                <p>Message:</p>
                <p>{this.state.message}</p>
                <br></br>
                <br></br>
            </div>
        )
    }
}