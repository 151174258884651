import { linspace } from './Functions'

const asc = arr => arr.sort((a, b) => a - b);

export function quantile(data, q) {
    const sorted = asc(data);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
}

export function mean(values) {
    let sum = 0;
    for (let i = 0; i < values.length; i++) {
        sum += values[i];
    }
    return sum / values.length;
}

export function variance(values) {
    const m = mean(values);

    let sum = 0;
    for (let i = 0; i < values.length; i++) {
        const inner = (values[i] - m)
        sum += inner * inner;
    }
    return sum / values.length;
}

export function std(values) {
    return Math.sqrt(variance(values))
}

class RollingQueue {
    constructor(size) {
        this.size = size
        this.buffer = []
    }

    push(number) {
        this.buffer.push(number);
        if (this.buffer.length > this.size) {
            this.buffer.shift();
        }
    }

    mean() {
        return mean(this.buffer);
    }

    var() {
        return variance(this.buffer);
    }

    std() {
        return std(this.buffer)
    }
}

export class RollingWindow {
    constructor(data, window) {
        this.data = data;
        this.window = window;
    }

    mean() {

        const queue = new RollingQueue(this.window);

        const result = [];
        for (let i = 0; i < this.data.length; i++) {
            queue.push(this.data[i]);
            result.push(queue.mean());           
        }

        return result;
    }

    std() {
        const queue = new RollingQueue(this.window);

        const result = [];
        for (let i = 0; i < this.data.length; i++) {
            queue.push(this.data[i]);
            result.push(queue.std());           
        }

        return result;
    }

    var() {
        const queue = new RollingQueue(this.window);

        const result = [];
        for (let i = 0; i < this.data.length; i++) {
            queue.push(this.data[i]);
            result.push(queue.var());           
        }

        return result;
    }
}

export function rolling(data, n) {
    if (n === undefined) {
        throw TypeError('Missing input n');
    }
    return new RollingWindow(data, n);
}

export function pctChange(data) {
    let last = Math.NaN;
    const result = [];
    for (let i = 0; i < data.length; i++) {
        const element = data[i];
        result.push((element - last) / last);
        last = element;
    }
    return result;
}

export function dailyVolatility(open, high, low, close) {

    // Rogers-Satchell volatility estimator

    // using 3 lagged period estimate
    // const n = 3;
    // const result = [];
    // for (let i = 2; i < open.length; i++) {

    //     const open_1 = open[i-2]
    //     const a_1 = Math.log(high[i-2]/low[i-2]);
    //     const a2_1 = a_1 * a_1 * 0.5;
    //     const b_1 = Math.log(close[i-2]/open[i-2]);
    //     const b2_1 = b_1 * b_1 * (2*Math.log(2)-1)
    //     const c_1 = a2_1 - b2_1

    //     const open_2 = close[i-2]
    //     const a_2 = Math.log(high[i-1]/low[i-1]);
    //     const a2_2 = a_2 * a_2 * 0.5;
    //     const b_2 = Math.log(close[i-1]/open[i-1]);
    //     const b2_2 = b_2 * b_2 * (2*Math.log(2)-1)
    //     const c_2 = a2_2 - b2_2

    //     const open_3 = close[i-1]
    //     const a_3 = Math.log(high[i]/low[i]);
    //     const a2_3 = a_3 * a_3 * 0.5;
    //     const b_3 = Math.log(close[i]/open[i]);
    //     const b2_3 = b_3 * b_3 * (2*Math.log(2)-1)
    //     const c_3 = a2_3 - b2_3

    //     const vol = (c_1 + c_2 + c_3) / n
    //     result.push(vol);
    //     // last = element;
    // }

    // using 3 lagged period estimate
    // const n = 3;
    // const result = [];
    // for (let i = 2; i < open.length; i++) {

    //     const a_1 = Math.log(high[i-2]/open[i-2]) * Math.log(high[i-2]/close[i-2]);
    //     const b_1 = Math.log(low[i-2]/open[i-2]) * Math.log(low[i-2]/close[i-2]);
    //     const c_1 = a_1 + b_1

    //     const a_2 = Math.log(high[i-1]/open[i-1]) * Math.log(high[i-1]/close[i-1]);
    //     const b_2 = Math.log(low[i-1]/open[i-1]) * Math.log(low[i-1]/close[i-1]);
    //     const c_2 = a_2 + b_2

    //     const a_3 = Math.log(high[i]/open[i]) * Math.log(high[i]/close[i]);
    //     const b_3 = Math.log(low[i]/open[i]) * Math.log(low[i]/close[i]);
    //     const c_3 = a_3 + b_3

    //     const vol = (c_1 + c_2 + c_3) / n
    //     result.push(vol);
    //     // last = element;
    // }


    const n = 10;
    const result = [];
    let weights = linspace(-1.0, 0.0, n);
    let wsum =  weights.reduce((a, b) => a + b);
    const k = 0.34 / (1.34 + (n+1)/(n-1));

    for (let i = 0; i < (n); i++) {
        result.push(Number.NaN);
    }

    for (let i = 0; i < (n); i++) {
        weights[i] /= wsum;
    }

    for (let i = n; i < open.length; i++) {

        let sigma_overnight = 0;
        let sigma_oc = 0;
        let sigma_rs = 0;
        let mu_overnight = 0;
        let mu_oc = 0;
        for (let u = 0; u < n; u++) {
            const idx = i-u;
            mu_overnight += Math.log(open[idx]/close[idx-1]) * weights[u];
            mu_oc += Math.log(close[idx]/open[idx]) * weights[u];
        }

        for (let u = 0; u < n; u++) {
            const idx = i-u;
            sigma_overnight += (Math.log(open[idx]/close[idx-1]) - mu_overnight) * weights[u];
            sigma_oc += (Math.log(close[idx]/open[idx]) - mu_oc) * weights[u];
            sigma_rs += (Math.log(high[idx]/close[idx])*Math.log(high[idx]/open[idx]) +
                        Math.log(low[idx]/close[idx])*Math.log(low[idx]/open[idx])) * weights[u];
        }

        const sigma_yz = sigma_overnight + k * sigma_oc + (1 - k) * sigma_rs;
        result.push(sigma_yz);
    }

    return result;
}

export function dropna(data) {
    const result = [];
    for (let i = 0; i < data.length; i++) {
        if (Number.isFinite(data[i])){
            result.push(data[i]);
        }
    }

    return result;
}

export function erf(x) {
    const sign = Math.sign(x);
    x = Math.abs(x);

    const a1 =  0.254829592;
    const a2 = -0.284496736;
    const a3 =  1.421413741;
    const a4 = -1.453152027;
    const a5 =  1.061405429;
    const p  =  0.3275911;

    const t = 1.0/(1.0 + p*x);
    const y = 1.0 - (((((a5*t + a4)*t) + a3)*t + a2)*t + a1)*t*Math.exp(-x*x);
    return sign * y;
}
export class NormalDistribution {
    constructor(mu, sigma) {
        this.mu = mu;
        this.sigma = sigma;
    }

    pdf(x) {
        const t1 = 1 / (this.sigma * Math.sqrt(2 * Math.PI));
        if (Array.isArray(x)) {
            const results = [];

            for (let i = 0; i < x.length; i++) {
                const t2 = ((x[i]-this.mu) / this.sigma);
                const t3 = -0.5 * t2 * t2;
                results.push(t1 * Math.exp(t3));
            }
            return results;
        }
        else {
            const t2 = ((x-this.mu) / this.sigma);
            const t3 = -0.5 * t2 * t2;
            return t1 * Math.exp(t3);
        }
    }

    cdf(x) {
        return 0.5 * (1 + erf( (x-this.mu) / (this.sigma*Math.sqrt(2)) ));
    }
}

export function fitNorm(values) {
    // Norm fit using mean, std
    const mu = mean(values);
    const sigma = std(values);

    return new NormalDistribution(mu, sigma)
}