import { PortfolioCollection } from "./PortfolioCollection";
import { useState } from 'react'
import {ReactComponent as SaveIcon} from '../content-save-outline.svg';

import "./SideBar.css"

export default function SideBar({
        tickers, 
        shares,
        total,
        weights,
        constituents,
        onTickerChanged, 
        onShareChanged, 
        onAddNew, 
        onApply,
        onClear,
        onSave,
    }) {

    const [hidden, setHidden] = useState(true);

    const onSaveClick = () => {
        onSave();

        setHidden(false);
        setTimeout(() => {
            setHidden(true);
        }, 2000);

    };

    let savedMessageClass = '';
    if (hidden) {
        savedMessageClass = 'side-hidden-text';
    }

    return (
        <div>
            <div>
                <h3>Weights</h3>
                <div>
                    Ticker @ Shares
                </div>

                <PortfolioCollection 
                    tickers={tickers} 
                    shares={shares} 
                    weights={weights}
                    constituents={constituents}
                    onTickerChanged={onTickerChanged}
                    onShareChanged={onShareChanged}
                />

                <div className="side-buttons">
                    <button className="side-btn" onClick={onAddNew}>New</button>
                    <button className="side-btn" onClick={onClear}>Remove</button>
                    <button className="side-save-btn" onClick={onSaveClick}>
                        <SaveIcon className='save-icon' width={24} height={24}/>
                    </button>
                </div>
                <div className="side-apply-row">
                    <button className="side-btn" onClick={onApply}>Apply</button>
                    <h4 className={savedMessageClass}>Saved</h4>
                </div>
                
            </div>

            <div>
                <h4 className="side-total-label">Total </h4>
                <h4 className="side-total-value">${total.toFixed(2)}</h4>
            </div>
        </div>
    );
}