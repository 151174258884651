import {Link} from 'react-router-dom'
import './NavBar.css'
import { useLocation } from 'react-router-dom'


function isCurrent(page, location) {
    return location.pathname === '/' + page;
}

function getClassName(page, location) {
    return isCurrent(page, location) ? 'active' : '';
}

export default function NavBar() {

    const location = useLocation();

    return (
        <div>
            <nav>
                <ul>
                    <li key={'home'} className={getClassName('', location)}>
                        <Link to="/">
                            Home
                        </Link>
                    </li>
                    <li key={'optimize'} className={getClassName('optimize', location)}>
                        <Link to="/optimize">
                            Optimize
                        </Link>
                    </li>
                    <li key={'screen'} className={getClassName('screen', location)}>
                        <Link to="/screen">
                            Screen
                        </Link>
                    </li>
                    <li key={'about'} className={getClassName('about', location)}>
                        <Link to="/about">
                            About
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}